import React, {Fragment, useEffect, useState} from "react";
import Intro from "./Intro";
import Process from "./Process";
import {cmsService} from "../../utils/axios";
import MainTextContent from "./MainTextContent";
import SpecialOffers from "./SpecialOffers";
import OfferCategories from "./OfferCategories";
import Brands from "./Brands";
import Benefits from "./Benefits";
import ExternalArticles from "./ExternalArticles";
import SeeAlso from "./SeeAlso";
import Testimonials from "./Testimonials";
import Newsletter from "./Newsletter";
import FAQSection from "./FAQSection";
import ExternalPortalLogos from "./ExternalPortalLogos";
import MetaTagsManipulator from "../../utils/MetaTagsManipulator";

function HomePage(props){

    const [homePage, setHomePage] = useState({
        ID: 1,
        URLSegment: "home",
        Title: "Strona główna",
        MenuTitle: "Strona główna",
        Content: "",
        LogosURL: [],
        VideoFrame: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/f_KUMcud9CE\" frameborder=\"0\" allow=\"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>",
    });


    useEffect(() => {

        cmsService("/HomePage?limit=1&relationdepth=0&add_fields=LogosURL").then(response => {
            let pageData = response.data.items[0];

            if(pageData) {
                setHomePage(pageData);

                MetaTagsManipulator.setTitle(pageData.Title);
                MetaTagsManipulator.setDescription(pageData.MetaDescription);
            }
        })
    },[]);

    return (
        <Fragment>
            <Intro />
            <SpecialOffers />
            <MainTextContent content={homePage.Content} video={homePage.VideoFrame}/>
            <Process />
            <OfferCategories />
            <Brands />
            <Benefits />
            <ExternalArticles/>
            <ExternalPortalLogos logos={homePage.LogosURL} />
            <SeeAlso />
            <Testimonials />
            <Newsletter />
            <FAQSection />
        </Fragment>
    );
}

export default HomePage;