import React, {Fragment, useEffect, useState} from "react";
import {FormGroup, Tooltip, Position} from "@blueprintjs/core";
import Validator from "../../../utils/Validator";
import InputErrorMessage from "../../Reservation/InputErrorMessage";
import MomentFormatter from "../../../utils/MomentFormatter";
import RegonDataMapper from "../../Reservation/BasicData/RegonDataMapper";
import {leasingOnlineService} from "../../../utils/axios";
import Dictionary from "../../Reservation/Dictionary";
import {DateInput} from "@blueprintjs/datetime";
import BikKrdTooltip from "../../Reservation/BasicData/BikKrdTooltip";
import CurrencyInput from "../../../components/CurrencyInput/CurrencyInput";

function CompanyBasicDataFormForCredit({company, formValidator, onChange}) {

    const OSOBA_FIZYCZNA_PROWADZACA_DZIALALNOSC_GOSPODARCZA = "099";
    const SPOLKA_CYWILNA = "019";

    const isLegalFormForIndividuals = (legalFormCode) =>
        [OSOBA_FIZYCZNA_PROWADZACA_DZIALALNOSC_GOSPODARCZA, SPOLKA_CYWILNA].includes(legalFormCode);

    const setCompanyDataFromRegon = (companyData) => {
        if (companyData !== null && !Array.isArray(companyData)) {
            onChange({
                ...company,
                name: companyData.companyName,
                location: companyData.city,
                creationDate: MomentFormatter.dateFromString(companyData.createDate),
                pkd: companyData.pkd,
                legalForm: RegonDataMapper.mapLegalForm(companyData.detailedLegalFormCode),
                voivodeship: RegonDataMapper.mapVoivodeship(companyData.voivodeship),
                accountingForm: isLegalFormForIndividuals(companyData.detailedLegalFormCode) ? "" : "FULL",
                loadedFromRegon: true,
                filledLocation: company.location != null && company.location !== ""
            });

        } else {
            onChange({
                ...company,
                loadedFromRegon: false,
                filledLocation: false
            });
        }
    }


    function isCompanyNotSet() {
        return company.name === "" && company.location === "" && company.pkd === "" && company.legalForm === "" && company.voivodeship === "";
    }

    function loadDataFromNip(value) {
        if (value.length === 10 && !company.loadedFromRegon) {
            leasingOnlineService("/getDataFromREGON?nip=" + value).then(response => {

                if (response.data.status !== 500) {
                    setCompanyDataFromRegon(response.data);
                }

            }).catch(() => onChange({
                ...company,
                loadedFromRegon: false
            }));
        }
    }

    useEffect(() => {
        if (company.nip) {
            loadDataFromNip(company.nip, isCompanyNotSet());
        }
    }, [company.nip, company.loadedFromRegon])

// get REGON data if NIP field is blur
    const nipBlurHandler = (event) => {
        const {value} = event.target;

        loadDataFromNip(value);
    }

    const companyCreateDateChangeHandler = date => onChange({...company, creationDate: date});

    const companyValueChangeHandler = (event) => {
        const {name, value} = event.target;

        onChange({...company, [name]: value});
    }

    const currencyChangeHandler = (name, value) =>
        onChange({
            ...company,
            [name]: value
        });

    return (
        <Fragment>
            <FormGroup
                className="form-group"
                label="NIP"
                labelFor="nip"
            >
                <input type="text"
                       ref={formValidator.register({
                           required: true,
                           pattern: /[0-9]{10}/,
                           maxLength: 10,
                           validate: value => Validator.validateNip(value)
                       })}
                       onBlur={nipBlurHandler}
                       name="nip"
                       value={company.nip}
                       disabled={company.loadedFromRegon}
                       onChange={companyValueChangeHandler}
                       placeholder="Np. 1234567890 (10 cyfr)"/>
                <InputErrorMessage error={formValidator.errors.nip} message="Wprowadź poprawny numer NIP (10 cyfr)"/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="PKD główne"
                labelFor="pkd"
            >
                <input type="text"
                       ref={formValidator.register({required: true})}
                       disabled={company.loadedFromRegon}
                       name="pkd"
                       value={company.pkd}
                       onChange={companyValueChangeHandler}/>
                <InputErrorMessage error={formValidator.errors.pkd}/>
            </FormGroup>

            <FormGroup
                className="form-group fill"
                label="Nazwa firmy"
                labelFor="name"
            >
                <input type="text"
                       ref={formValidator.register({required: true})}
                       disabled={company.loadedFromRegon}
                       name="name"
                       value={company.name}
                       onChange={companyValueChangeHandler}/>
                <InputErrorMessage error={formValidator.errors.name}/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Miejscowość"
                labelFor="location"
            >
                <input type="text"
                       ref={formValidator.register({required: true})}
                       disabled={company.filledLocation}
                       name="location"
                       value={company.location}
                       onChange={companyValueChangeHandler}/>
                <InputErrorMessage error={formValidator.errors.location}/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Województwo"
                labelFor="voivodeship"
            >
                <select className="like-btn"
                        ref={formValidator.register({required: true})}
                        name="voivodeship"
                        disabled={company.filledLocation}
                        value={company.voivodeship}
                        onChange={companyValueChangeHandler}>
                    <option value=""></option>
                    {Dictionary.createDictionaryOptionsValueAsKey(Dictionary.VOIVODESHIP)}
                </select>
                <InputErrorMessage error={formValidator.errors.voivodeship}/>
            </FormGroup>


            <FormGroup
                className="form-group"
                label="Data założenia dz. gosp."
                labelFor="creation-date"
            >
                <DateInput
                    {...MomentFormatter.datePickerFormatProps()}
                    disabled={company.loadedFromRegon}
                    popoverProps={{
                        usePortal: false
                    }}
                    inputProps={{
                        name: "creationDate",
                        inputRef: formValidator.register({required: true})
                    }}
                    maxDate={new Date()}
                    minDate={new Date(new Date().setFullYear(1945))}
                    onChange={companyCreateDateChangeHandler}
                    placeholder={"Wprowadź datę w formacie rrrr-mm-dd"}
                    value={company.creationDate}
                />
                <InputErrorMessage error={formValidator.errors.creationDate}
                                   message="Wprowadź datę w formacie rrrr-mm-dd"/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Forma prawna"
                labelFor="legalForm"
            >
                <select className="like-btn"
                        ref={formValidator.register({required: true})}
                        name="legalForm"
                        disabled={company.loadedFromRegon}
                        value={company.legalForm}
                        onChange={companyValueChangeHandler}>
                    <option value=""></option>
                    {Dictionary.createDictionaryOptionsValueAsKey(Dictionary.LEGAL_FORM)}
                </select>
                <InputErrorMessage error={formValidator.errors.legalForm}/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Forma księgowości"
                labelFor="accountingForm"
            >
                <select className="like-btn"
                        ref={formValidator.register({required: true})}
                        name="accountingForm"
                        value={company.accountingForm}
                        onChange={companyValueChangeHandler}>
                    <option value=""></option>
                    {Dictionary.createDictionaryOptionsValueAsKey(Dictionary.ACCOUNTING_TYPE)}
                </select>
                <InputErrorMessage error={formValidator.errors.accountingForm}/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Przybliżona liczba pracowników"
                labelFor="employeesNumber"
            >
                <input type="number"
                       ref={formValidator.register({required: true, min: 0, max: 1000000})}
                       name="employeesNumber"
                       value={company.employeesNumber}
                       onChange={companyValueChangeHandler}/>
                <InputErrorMessage error={formValidator.errors.employeesNumber}/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Status firmy w bazach BIK/KRD"
                labelFor="bikStatus"
            >
                <BikKrdTooltip>
                    <select className="like-btn"
                            ref={formValidator.register({required: true})}
                            name="bikStatus"
                            value={company.bikStatus}
                            onChange={companyValueChangeHandler}>
                        <option value=""></option>
                        {Dictionary.createDictionaryOptionsValueAsKey(Dictionary.BIK_KRD_STATUS)}
                    </select>
                </BikKrdTooltip>
                <InputErrorMessage error={formValidator.errors.bikStatus}/>
            </FormGroup>

        </Fragment>
    );

}

export default CompanyBasicDataFormForCredit;