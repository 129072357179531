import alfaRomeoIcon from '../assets/images/content/brands_small/alfa_romeo.png';
import alpineIcon from '../assets/images/content/brands_small/alpine.png';
import astonMartinIcon from '../assets/images/content/brands_small/aston_martin.png';
import audiIcon from '../assets/images/content/brands_small/audi.png';
import chryslerIcon from '../assets/images/content/brands_small/chrysler.png';
import bentleyIcon from '../assets/images/content/brands_small/bentley.png';
import bmwIcon from '../assets/images/content/brands_small/bmw.png';
import citroenIcon from '../assets/images/content/brands_small/citroen.png';
import cupraIcon from '../assets/images/content/brands_small/cupra.png';
import daciaIcon from '../assets/images/content/brands_small/dacia.png';
import dodgeIcon from '../assets/images/content/brands_small/dodge.png';
import dsIcon from '../assets/images/content/brands_small/ds.png';
import fiatIcon from '../assets/images/content/brands_small/fiat.png';
import fordIcon from '../assets/images/content/brands_small/ford.png';
import hondaIcon from '../assets/images/content/brands_small/honda.png';
import hyundaiIcon from '../assets/images/content/brands_small/hyundai.png';
import isuzuIcon from '../assets/images/content/brands_small/isuzu.png';
import jaguarIcon from '../assets/images/content/brands_small/jaguar.png';
import jeepIcon from '../assets/images/content/brands_small/jeep.png';
import kiaIcon from '../assets/images/content/brands_small/kia.png';
import landRoverIcon from '../assets/images/content/brands_small/land_rover.png';
import lexusIcon from '../assets/images/content/brands_small/lexus.png';
import lotusIcon from '../assets/images/content/brands_small/lotus.png';
import maseratiIcon from '../assets/images/content/brands_small/maserati.png';
import mazdaIcon from '../assets/images/content/brands_small/mazda.png';
import mclarenIcon from '../assets/images/content/brands_small/mclaren.png';
import mercedesIcon from '../assets/images/content/brands_small/mercedes.png';
import miniIcon from '../assets/images/content/brands_small/mini.png';
import mitsubishiIcon from '../assets/images/content/brands_small/mitsubishi.png';
import nissanIcon from '../assets/images/content/brands_small/nissan.png';
import opelIcon from '../assets/images/content/brands_small/opel.png';
import peugeotIcon from '../assets/images/content/brands_small/peugeot.png';
import polestarIcon from '../assets/images/content/brands_small/polestar.png';
import porscheIcon from '../assets/images/content/brands_small/porsche.png';
import renaultIcon from '../assets/images/content/brands_small/renault.png';
import rollsroyceIcon from '../assets/images/content/brands_small/rollsroyce.png';
import seatIcon from '../assets/images/content/brands_small/seat.png';
import skodaIcon from '../assets/images/content/brands_small/skoda.png';
import smartIcon from '../assets/images/content/brands_small/smart.png';
import ssangYongIcon from '../assets/images/content/brands_small/ssangyong.png';
import subaruIcon from '../assets/images/content/brands_small/subaru.png';
import suzukiIcon from '../assets/images/content/brands_small/suzuki.png';
import teslaIcon from '../assets/images/content/brands_small/tesla.png';
import toyotaIcon from '../assets/images/content/brands_small/toyota.png';
import volkswagenIcon from '../assets/images/content/brands_small/volkswagen.png';
import volvoIcon from '../assets/images/content/brands_small/vovlo.png';
import seresIcon from '../assets/images/content/brands_small/seres.png';
import skywellIcon from '../assets/images/content/brands_small/skywell.png';
import ferrariIcon from '../assets/images/content/brands_small/ferrari.png';
import lamboIcon from '../assets/images/content/brands_small/lambo.png';

import alfaRomeoLogo from '../assets/images/content/brands_big/alfa_romeo_logo.png';
import audiLogo from '../assets/images/content/brands_big/audi_logo.png';
import bmwLogo from '../assets/images/content/brands_big/logo_bmw_2020.png';
import citroenLogo from '../assets/images/content/brands_big/citroen_logo.png';
import cupraLogo from '../assets/images/content/brands_big/cupra.png';
import daciaLogo from '../assets/images/content/brands_big/dacia_logo.png';
import dodgeLogo from '../assets/images/content/brands_big/dodge_logo.png';
import fiatLogo from '../assets/images/content/brands_big/fiat_logo.png';
import fordLogo from '../assets/images/content/brands_big/ford_logo.png';
import hyundaiLogo from '../assets/images/content/brands_big/hyundai_logo.png';
import jaguarLogo from '../assets/images/content/brands_big/jaguar_logo.png';
import jeepLogo from '../assets/images/content/brands_big/jeep_logo.png';
import kiaLogo from '../assets/images/content/brands_big/kia_logo.png';
import landRoverLogo from '../assets/images/content/brands_big/land-rover_logo.png';
import lexusLogo from '../assets/images/content/brands_big/lexus_logo2.png';
import mazdaLogo from '../assets/images/content/brands_big/mazda_logo.png';
import mercedesLogo from '../assets/images/content/brands_big/mercedes-benz_logo.png';
import miniLogo from '../assets/images/content/brands_big/mini_logo.png';
import mitsubishiLogo from '../assets/images/content/brands_big/mitsubishi_logo.png';
import nissanLogo from '../assets/images/content/brands_big/nissan_logo.png';
import opelLogo from '../assets/images/content/brands_big/opel_logo.png';
import peugeotLogo from '../assets/images/content/brands_big/peugeot_logo.png';
import renaultLogo from '../assets/images/content/brands_big/renault_logo.png';
import seatLogo from '../assets/images/content/brands_big/seat_logo.png';
import skodaLogo from '../assets/images/content/brands_big/skoda_logo.png';
import smartLogo from '../assets/images/content/brands_big/smart_logo.png';
import ssangYongLogo from '../assets/images/content/brands_big/ssangyong_logo-01.png';
import suzukiLogo from '../assets/images/content/brands_big/suzuki_logo.png';
import toyotaLogo from '../assets/images/content/brands_big/toyota_logo.png';
import volkswagenLogo from '../assets/images/content/brands_big/volkswagen_2019_logo.png';
import volvoLogo from '../assets/images/content/brands_big/vovlo_logo.png';
import ivecoLogo from '../assets/images/content/brands_big/iveco.png';
import dsLogo from '../assets/images/content/brands_big/ds_logo.png';
import cadillacLogo from '../assets/images/content/brands_small/cadillac.png';
import chevroletLogo from '../assets/images/content/brands_small/chevrolet.png';
import infinitiLogo from '../assets/images/content/brands_small/infiniti.png';
import lanciaLogo from '../assets/images/content/brands_small/lancia.png';
import manLogo from '../assets/images/content/brands_small/man.png';
import mgMotorIcon from '../assets/images/content/brands_small/mgmotor.png';
import baicIcon from '../assets/images/content/brands_small/mgmotor.png';
import bydIcon from '../assets/images/content/brands_small/byd.png';
import jaeccoIcon from '../assets/images/content/brands_small/jaecco.png';
import maxusIcon from '../assets/images/content/brands_small/maxus.png';
import omodaIcon from '../assets/images/content/brands_small/omoda.png';


const BrandImages = {
    'alfa-romeo': { smallIcon: alfaRomeoIcon, logo: alfaRomeoLogo},
    'alpine': { smallIcon: alpineIcon, logo: alpineIcon},
    'astion-martin': { smallIcon: astonMartinIcon, logo: astonMartinIcon},
    'audi': { smallIcon: audiIcon, logo: audiLogo},
    'bentley': { smallIcon: bentleyIcon, logo: bentleyIcon},
    'bmw': { smallIcon: bmwIcon, logo: bmwLogo},
    'citroen': { smallIcon: citroenIcon, logo: citroenLogo},
    'cadillac': { smallIcon: cadillacLogo, logo: cadillacLogo},
    'chevrolet': { smallIcon: chevroletLogo, logo: chevroletLogo},
    'cupra': { smallIcon: cupraIcon, logo: cupraLogo},
    'dacia': { smallIcon: daciaIcon, logo: daciaLogo},
    'dodge': { smallIcon: dodgeIcon, logo: dodgeLogo},
    'ds': { smallIcon: dsIcon, logo: dsIcon},
    'fiat': { smallIcon: fiatIcon, logo: fiatLogo},
    'ford': { smallIcon: fordIcon, logo: fordLogo},
    'honda': { smallIcon: hondaIcon, logo: hondaIcon},
    'hyundai': { smallIcon: hyundaiIcon, logo: hyundaiLogo},
    'infiniti': { smallIcon: infinitiLogo, logo: infinitiLogo},
    'isuzu': { smallIcon: isuzuIcon, logo: isuzuIcon},
    'jaguar': { smallIcon: jaguarIcon, logo: jaguarLogo},
    'jeep': { smallIcon: jeepIcon, logo: jeepLogo},
    'kia': { smallIcon: kiaIcon, logo: kiaLogo},
    'lancia': { smallIcon: lanciaLogo, logo: lanciaLogo},
    'land-rover': { smallIcon: landRoverIcon, logo: landRoverLogo},
    'lexus': { smallIcon: lexusIcon, logo: lexusLogo},
    'lotus': { smallIcon: lotusIcon, logo: lotusIcon},
    'man': { smallIcon: manLogo, logo: manLogo},
    'maserati': { smallIcon: maseratiIcon, logo: maseratiIcon},
    'mazda': { smallIcon: mazdaIcon, logo: mazdaLogo},
    'mclaren': { smallIcon: mclarenIcon, logo: mclarenIcon},
    'mercedes-benz': { smallIcon: mercedesIcon, logo: mercedesLogo},
    'mini-[bmw]': { smallIcon: miniIcon, logo: miniLogo},
    'mitsubishi': { smallIcon: mitsubishiIcon, logo: mitsubishiLogo},
    'nissan': { smallIcon: nissanIcon, logo: nissanLogo},
    'opel': { smallIcon: opelIcon, logo: opelLogo},
    'peugeot': { smallIcon: peugeotIcon, logo: peugeotLogo},
    'polestar': { smallIcon: polestarIcon, logo: polestarIcon},
    'porsche': { smallIcon: porscheIcon, logo: porscheIcon},
    'renault': { smallIcon: renaultIcon, logo: renaultLogo},
    'rollsRoyce': { smallIcon: rollsroyceIcon, logo: rollsroyceIcon},
    'seat': { smallIcon: seatIcon, logo: seatLogo},
    'skoda': { smallIcon: skodaIcon, logo: skodaLogo},
    'smart': { smallIcon: smartIcon, logo: smartLogo},
    'ssang-yong': { smallIcon: ssangYongIcon, logo: ssangYongLogo},
    'subaru': { smallIcon: subaruIcon, logo: subaruIcon},
    'suzuki': { smallIcon: suzukiIcon, logo: suzukiLogo},
    'tesla': { smallIcon: teslaIcon, logo: teslaIcon},
    'toyota': { smallIcon: toyotaIcon, logo: toyotaLogo},
    'volkswagen': { smallIcon: volkswagenIcon, logo: volkswagenLogo},
    'volvo': { smallIcon: volvoIcon, logo: volvoLogo},
    'iveco': { smallIcon: ivecoLogo, logo: ivecoLogo},
    'ssangyong': { smallIcon: ssangYongIcon, logo: ssangYongLogo},
    'skywell': { smallIcon: skywellIcon, logo: skywellIcon},
    'seres': { smallIcon: seresIcon, logo: seresIcon},
    'ds-automobiles': { smallIcon: dsLogo, logo: dsLogo},
    'lamborghini': { smallIcon: lamboIcon, logo: lamboIcon},
    'ferrari': { smallIcon: ferrariIcon, logo: ferrariIcon},
    'chrysler': { smallIcon: chryslerIcon, logo: chryslerIcon},
    'mg': { smallIcon: mgMotorIcon, logo: mgMotorIcon},
    'byd': { smallIcon: bydIcon, logo: bydIcon},
    'baic': { smallIcon: baicIcon, logo: baicIcon},
    'jaecoo': { smallIcon: jaeccoIcon, logo: jaeccoIcon},
    'maxus': { smallIcon: maxusIcon, logo: maxusIcon},
    'omoda': { smallIcon: omodaIcon, logo: omodaIcon}
}

export default BrandImages;