import React, {Fragment, useContext, useEffect, useState} from 'react';
import MainMenu from '../../components/MainMenu/MainMenu'
import CustomerTypeSwitch from '../../components/CustomerTypeSwitch/CustomerTypeSwitch'
import MobileMenu from '../../components/MobileMenu/MobileMenu'
import ProductMenu from '../../components/ProductMenu/ProductMenu'

import logoIcon from '../../assets/images/icons/logo.png';
import envelopeIcon from '../../assets/images/icons/envelope.svg';
import phoneIcon from '../../assets/images/icons/phone.svg';
import SearchField from "../../components/SearchField/SearchField";
import {Link, useHistory} from "react-router-dom";
import URLSearchParamsConverter from "../../utils/URLSearchParamsConverter";
import analyitics from "../../utils/analytics";
import ContactDialog from "../../components/ContactDialog/ContactDialog";
import MobileCallButton from "../../components/MobileCallButton/MobileCallButton";
import MobileFavouriteButton from "../../components/MobileFavouriteButton/MobileFavouriteButton";

import {Position, Tooltip} from "@blueprintjs/core";
import {Icon, Intent} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {FavouriteVehiclesContext} from "../../context/favourite-vehicles-context";

function Header(props) {
    const [showNavigation, setShowNavigation] = useState(false);
    const [showProductSearch, setShowProductSearch] = useState(false);
    const [isOpenContactDialog, setIsOpenContactDialog] = useState(false);
    const [isOpenFavouriteDialog, setIsOpenFavouriteDialog] = useState(false);
    const [amountOfFavouriteVehicles, setAmountOfFavouriteVehicles] = useState(0);
    const amountOfVehicles = useContext(FavouriteVehiclesContext);
    const history = useHistory();

    useEffect(() => {
        setAmountOfFavouriteVehicles(amountOfVehicles.value);
    }, [amountOfVehicles.value]);

    if (isOpenFavouriteDialog === true) {
        window.location.href = "/ulubione";
    }

    let toggleNavigationHandler = () => {
        setShowNavigation(prevState => !prevState);
    }

    let closeNavigationHandler = () => {
        setShowNavigation(false);
    }

    let openPopupSearchHandler = () => {
        setShowProductSearch(true);
    }

    let closeProductMenuHandler = () => {
        setShowProductSearch(false);
    }

    let carSelectedHandler = (car) => {
        searchButtonClickHandler(car);
    }

    let searchButtonClickHandler = (car) => {

        analyitics.sendEvent({
            category: 'Wyszukiwarka',
            action: 'Kliknięcie w lupkę'
        });

        let searchParameters = {
            brands: (car.brand ? [{name: car.brand}] : []),
            models: (car.model ? [{name: car.model}] : []),
            nip: (car.nip ? [{name: car.nip}] : [])
        }

        history.push({
            pathname: "/lista-ofert",
            search: URLSearchParamsConverter.createURLSearchString(searchParameters)
        });
        setShowProductSearch(false);
    }

    const openContactDialogHandler = () => {
        analyitics.sendEvent({
            category: 'Koperta',
            action: 'Klikniecie',
            label: 'Kliknieto w koperte',
            value: 1
        });

        setIsOpenContactDialog(true);
    }

    const phoneClickHandler = () => {
        analyitics.sendEvent({
            category: 'Telefon',
            action: 'Klikniecie',
            label: 'Kliknieto w nr telefonu',
            value: 1
        });
    }

    const getFavouriteVehicleCounterSwitch = () => {
        if(amountOfFavouriteVehicles !== 0) {
            return (
                <div className="favourite-vehicle-counter">{getAmountOfFavouriteVehicles()}</div>
            );
        }
    }

    const getAmountOfFavouriteVehicles = () => {
        if (amountOfFavouriteVehicles === 0) {
            return '\xa0';
        }

        return amountOfFavouriteVehicles;
    }

    const openFavouriteVehiclesHandler = () => {
        history.push({
            pathname: "/ulubione",
        });
    }

    return (
        <Fragment>
            <header>
                <div className="container flex fullwidth">
                    <Link id="logo" to="/"><img src={logoIcon} alt="GO-auto"/></Link>
                    <MainMenu clicked={toggleNavigationHandler} closeNavigationClicked={closeNavigationHandler}
                              showNavigation={showNavigation}/>
                    <CustomerTypeSwitch/>
                    <div className="top-search">
                        <SearchField onItemSelect={carSelectedHandler}/>
                    </div>
                    <div id="top-contact" className="flex">
                        <div className="hours">
                            <div className="hour-line">
                                <div className="day">Pon - Pt</div>
                                <div className="hour"> 08:00 - 20:00</div>
                            </div>
                        </div>
                        <div id="favourite-button">
                            <Tooltip content="Ulubione auta" position={Position.RIGHT}>
                                <button className="btn-clean" onClick={openFavouriteVehiclesHandler}>
                                    <Icon icon={IconNames.STAR} iconSize={Icon.SIZE_LARGE} intent={Intent.PRIMARY}/>
                                </button>
                            </Tooltip>
                            {getFavouriteVehicleCounterSwitch()}
                        </div>
                        <div id="top-mail">
                            <button className="btn-clean" onClick={openContactDialogHandler}>
                                <img src={envelopeIcon} alt=""/>
                            </button>
                        </div>
                        <div id="top-phone">
                            <a href={"tel:" + props.seller.phone} className="flex" onClick={phoneClickHandler}>
                                <img src={phoneIcon} alt=""/>
                                <span>{props.seller.phone}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </header>
            <MobileCallButton clicked={() => setIsOpenContactDialog(true)}/>
            <MobileFavouriteButton
                clicked={() => setIsOpenFavouriteDialog(true)}
                favouritesAmount={getAmountOfFavouriteVehicles()}
                amount={amountOfFavouriteVehicles}
            />
            <MobileMenu menuClicked={toggleNavigationHandler} searchClicked={openPopupSearchHandler}/>
            <ProductMenu searchOptions={props.searchOptions} showInMobile={showProductSearch}
                         closeClicked={closeProductMenuHandler}/>
            <ContactDialog isOpen={isOpenContactDialog}
                           onClose={() => setIsOpenContactDialog(false)}/>
        </Fragment>
    )
}

export default Header;