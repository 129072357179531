import React, {useState} from "react";
import MediaQuery from "react-responsive";
import {Collapse} from "@blueprintjs/core";
import VehicleCondition from "../../utils/VehicleCondition";
import akolBanner from "../../assets/images/content/wiarygodny_dostawca.png";


function VehicleInfo(props) {

    const [isOpen, setIsOpen] = useState(false);

    const openClickHandler = () => setIsOpen(prevState => !prevState);

    const salesDocumentMapper = () => {
        switch(props.offer.salesDocument) {
            case("FAKTURA_VAT"):
                return "Faktura VAT";
            case("VAT_MARZA"):
                return "Faktura VAT Marża";
            case("UMOWA_KS"):
                return "Umowa kupna-sprzedaży";
            case("VAT_ZW"):
                return "Faktura VAT zwolniony";
        }
    }

    let wiarygodnyDostawcaOffer = () => {
        let idString = props.offer.id.toString();
        let idLength = idString.length;
        return idLength === 10 && (idString.startsWith("2") || idString.startsWith("3"));
    }

    let contentBox = (
        <div className="offerBoxContent">
            <div className="flex">
                <div className="column">
                    <div className="line flex">
                        <div className="line-left">
                            Stan:
                        </div>
                        <div className="line-right">
                            {VehicleCondition[props.offer.condition]}
                        </div>
                    </div>
                    <div className="line flex">
                        <div className="line-left">
                            Rok produkcji:
                        </div>
                        <div className="line-right">
                            {props.offer.manufactureYear}
                        </div>
                    </div>
                    <div className="line flex">
                        <div className="line-left">
                            Przebieg:
                        </div>
                        <div className="line-right">
                            {props.offer.mileage} km
                        </div>
                    </div>

                    <div className="line flex">
                        <div className="line-left">
                            Typ nadwozia:
                        </div>
                        <div className="line-right">
                            {props.offer.bodyType}
                        </div>
                    </div>


                </div>

                <div className="column">
                    <div className="line flex">
                        <div className="line-left">
                            Silnik:
                        </div>
                        <div className="line-right">
                            {props.offer.engineCapacity} cm<sup>3</sup>
                            ({props.offer.enginePowerHP} KM)
                        </div>
                    </div>

                    <div className="line flex">
                        <div className="line-left">
                            Napęd:
                        </div>
                        <div className="line-right">
                            {props.offer.driveType}
                        </div>
                    </div>

                    <div className="line flex">
                        <div className="line-left">
                            Skrzynia biegów:
                        </div>
                        <div className="line-right">
                            {props.offer.gearboxType}
                        </div>
                    </div>
                    <div className="line flex">
                        <div className="line-left">
                            Paliwo:
                        </div>
                        <div className="line-right">
                            {props.offer.fuelType}
                        </div>
                    </div>
                    {   props.offer.range !== null && props.offer.fuelType === 'Elektryczny' &&
                        <div className="line flex">
                            <div className="line-left">
                                Zasięg:
                            </div>
                            <div className="line-right">
                                {props.offer.range} km
                            </div>
                        </div>
                    }
                </div>

                <div className="column">
                    <div className="line flex">
                        <div className="line-left">
                            Liczba drzwi:
                        </div>
                        <div className="line-right">
                            {props.offer.numberOfDoors}
                        </div>
                    </div>

                    <div className="line flex">
                        <div className="line-left">
                            Liczba siedzeń:
                        </div>
                        <div className="line-right">
                            {props.offer.numberOfSeats}
                        </div>
                    </div>

                    <div className="line flex">
                        <div className="line-left">
                            Kolor:
                        </div>
                        <div className="line-right">
                            {props.offer.color}
                        </div>
                    </div>

                    <div className="line flex">
                        <div className="line-left">
                            Dokument sprzedaży:
                        </div>
                        <div className="line-right">
                            {salesDocumentMapper()}
                        </div>
                    </div>

                    {props.offer.vin &&
                    <div className="line flex">
                        <div className="line-left">
                            VIN:
                        </div>
                        <div className="line-right">
                            {props.offer.vin}
                        </div>
                    </div>
                    }


                </div>
            </div>
        </div>);


    return (
        <div className="single-info single-box">
            {wiarygodnyDostawcaOffer() && <img src={akolBanner}/>}
            <h3>Informacje o pojeździe</h3>
            <MediaQuery maxDeviceWidth={767}>
            <span className="onlymobile openBox btn" onClick={openClickHandler}>
                <i className="fa fa-navicon"></i> Szczegóły
            </span>
                <Collapse isOpen={isOpen} keepChildrenMounted={true}>
                    {contentBox}
                </Collapse>
            </MediaQuery>

            <MediaQuery minDeviceWidth={768}>
                {contentBox}
            </MediaQuery>


        </div>
    );
}

export default VehicleInfo;