import React, {Fragment, useEffect, useState} from "react";

function VehicleTypeSwitch(props) {

    //default value for FilterDrawer vehicleType search parameter
    useEffect(() => {
        props.onChange(props.value ? props.value : "OSOBOWE");
    }, [])

    const [isActive, setIsActive] = useState({
        btnPassangerCarIsActive : props.value !== "DOSTAWCZE",
        btnDeliveryCarIsActive : props.value === "DOSTAWCZE"
    })

    let styleForPassengerCar = isActive.btnPassangerCarIsActive ? "active" : "inactive";
    let styleForDeliveryCar = isActive.btnDeliveryCarIsActive ? "active" : "inactive";

    const changeVehicleTypeToPassengerCar = () => {
        props.onChange("OSOBOWE");

        setIsActive( prevState => ({
            ...prevState,
            btnPassangerCarIsActive: true,
            btnDeliveryCarIsActive: false
        }))
    }

    const changeVehicleTypeToDeliveryCar = () => {
        props.onChange("DOSTAWCZE");

        setIsActive(prevState => ({
            ...prevState,
            btnPassangerCarIsActive: false,
            btnDeliveryCarIsActive: true
        }))
    }

    return (
        <Fragment>
            <div className="searchFromButtons flex">
                <button type="button" className={styleForPassengerCar} onClick={changeVehicleTypeToPassengerCar}>Osobowe</button>
                <button type="button" className={styleForDeliveryCar} onClick={changeVehicleTypeToDeliveryCar}>Dostawcze</button>
            </div>
        </Fragment>
    );
}

export default VehicleTypeSwitch;