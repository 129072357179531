import React from "react";
import MomentFormatter from "../../utils/MomentFormatter";
import {DateInput} from "@blueprintjs/datetime";

function FormattedDateInput({name, value, onChange, inputRef = null, showOnlyFuture = false, showOnlyPast = false, placeHolder=""}){
    let minDate = new Date(new Date().setFullYear(1945));
    let maxDate = (d => new Date(d.getFullYear() + 100, d.getMonth(), d.getDate()))(new Date());

    if(showOnlyFuture && showOnlyPast === false){
        minDate = new Date();
    }

    if(showOnlyPast && showOnlyFuture === false){
        maxDate = new Date();
    }


    return (
        <DateInput
            {...MomentFormatter.datePickerFormatProps()}
            popoverProps={{
                usePortal: false
            }}
            inputProps={{
                inputRef: inputRef,
                name: name
            }}
            minDate={minDate}
            maxDate={maxDate}
            onChange={onChange}
            placeholder={placeHolder === "" ? "Wprowadź datę w formacie rrrr-mm-dd" : placeHolder}
            value={value} />
    );
}

export default FormattedDateInput;