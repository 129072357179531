import React, {Fragment, useContext, useEffect, useState} from "react";
import BaseInstallment from "../BaseInstallment";
import {CustomerTypeContext, isCustomerCompany} from "../../../../context/customer-type-context";
import MathUtils from "../../../../utils/MathUtils";
import InstallmentSubmitBox from "../InstallmentSubmitBox";
import analytics from "../../../../utils/analytics";
import ContactDialog from "../../../../components/ContactDialog/ContactDialog";
import ButtonChooser from "../ButtonChooser";
import RentalCalculationSummary from "./RentalCalculationSummary";
import LabelWithInfo from "../../../../components/LabelWithInfo/LabelWithInfo";

function RentalInstallmentCalculator(props) {

    const customerContext = useContext(CustomerTypeContext);


    let vehiclePrice = isCustomerCompany(customerContext) ? props.offer.minimumPriceNet : props.offer.minimumPriceGross;

    const {installments, company, selectedOptions} = props.offer.rentalOffer;

    const matchInstallmentCriteria = (installment, calculationParameters) => {
        return installment.customerType === calculationParameters.customerType
            && installment.initialFeePercentage == calculationParameters.initialFeePercentage
            && installment.contractLength == calculationParameters.contractLength
            && installment.vehicleMileage == calculationParameters.vehicleMileage;
    }

    let defaultInstallment = installments
        .filter(installment => installment.customerType === customerContext[0])
        .reduce((prev, curr) => prev.value < curr.value ? prev : curr);

    // if(props.initialCalculation){
    //     defaultInstallment = installments
    //         .find(installment => matchInstallmentCriteria(installment, {
    //             vehicleMileage: props.initialCalculation ? props.initialCalculation.vehicleMileage : defaultInstallment.vehicleMileage,
    //             initialFeePercentage: props.initialCalculation ? MathUtils.percentageOfValue(vehiclePrice, props.initialCalculation.initialFeeValueNet) : defaultInstallment.initialFeePercentage,
    //             contractLength:  props.initialCalculation ? props.initialCalculation.contractLength : defaultInstallment.contractLength,
    //             customerType: customerContext[0]
    //         }))
    // }

    const [calculationParameters, setCalculationParameters] = useState(
        {
            vehicleMileage: props.initialCalculation ? props.initialCalculation.vehicleMileageLimit : defaultInstallment.vehicleMileage,
            initialFeePercentage: props.initialCalculation ? MathUtils.percentageOfValue(vehiclePrice, props.initialCalculation.initialFeeValueNet) : defaultInstallment.initialFeePercentage,
            contractLength:  props.initialCalculation ? props.initialCalculation.contractLength : defaultInstallment.contractLength,
            customerType: customerContext[0]
        }
    );

    const [calculationResult, setCalculationResult] = useState(defaultInstallment);




    function isMileageLowerThanOveralLimit(mileage, contractLength) {
        return mileage * (contractLength / 12) <= company.vehicleMileageLimit;
    }

    function getAvailableContractLengths(installments){
        return installments
            .filter(installment => installment.customerType === customerContext[0])
            .reduce((acc, curr) => acc.includes(curr.contractLength) ?
                acc : acc.concat(curr.contractLength), []).sort((a, b) => (a-b))
    }


    function getAvailableMileages(installments, contractLength){
        return installments
            .filter(installment => installment.customerType === customerContext[0])
            .reduce((acc, curr) => acc.includes(curr.vehicleMileage) ?
                acc : acc.concat(curr.vehicleMileage), [])
            .sort((a, b) => (a-b))
            .filter(mileage => isMileageLowerThanOveralLimit(mileage, contractLength))
            .map(mileage => mileage / 1000)
    }

    const [allowedCalculationParameters, setAllowedCalculationParameters] = useState({
        availableContractLengths: getAvailableContractLengths(installments),
        availableVehicleMileages: getAvailableMileages(installments, defaultInstallment.contractLength),
        availableInitialFees: installments
            .filter(installment => installment.customerType === customerContext[0])
            .reduce((acc, curr) => acc.includes(curr.initialFeePercentage) ?
                acc : acc.concat(curr.initialFeePercentage), []).sort((a, b) => (a-b))

    });

    useEffect(() => {

        const installment = props.offer.rentalOffer.installments.find(installment =>
            matchInstallmentCriteria(installment, calculationParameters));

        setCalculationResult(installment);


    }, [calculationParameters]);

    useEffect(() => {

        const availableInitialFees = installments
            .filter(installment => installment.customerType === customerContext[0])
            .reduce((acc, curr) => acc.includes(curr.initialFeePercentage) ?
                acc : acc.concat(curr.initialFeePercentage), []).sort((a, b) => (a-b));

        setAllowedCalculationParameters(prevState => ({
            ...prevState,
            availableInitialFees: availableInitialFees
        }));

        setCalculationParameters(prevState => ({
            ...prevState,
            initialFeePercentage: availableInitialFees.includes(calculationParameters.initialFeePercentage) ? calculationParameters.initialFeePercentage : availableInitialFees[0],
            customerType: customerContext[0]
        }));

    }, [customerContext[0]]);

    useEffect(() => {
        setAllowedCalculationParameters(prevState => ({
            ...prevState,
            availableVehicleMileages: getAvailableMileages(installments, calculationParameters.contractLength)
        }));

    }, [calculationParameters.contractLength]);

    useEffect(() => {
        setAllowedCalculationParameters(prevState => ({
            ...prevState,
            availableContractLengths: getAvailableContractLengths(installments)
                .filter(contractLength => isMileageLowerThanOveralLimit(calculationParameters.vehicleMileage, contractLength))
        }));

    }, [calculationParameters.vehicleMileage]);


    const contractLengthChangeHandler = (newContractLength) => {
        setCalculationParameters(prevState => {

            if (prevState.contractLength !== newContractLength) {
                analytics.sendEvent({
                    category: 'OL - zmiana miesiecy',
                    action: 'Klikniecie',
                    label: 'Kliknieto w ' + newContractLength,
                    value: 1
                });
            }

            return {
                ...prevState,
                contractLength: newContractLength
            };
        });
    };

    const initialFeeChangeHandler = (initialFee) => {
        setCalculationParameters(prevState => ({...prevState, initialFeePercentage: initialFee}));
    };

    const vehicleMileageChangeHandler = (vehicleMileage) => {
        setCalculationParameters(prevState => ({...prevState, vehicleMileage: vehicleMileage * 1000}));
    };


    const [isOpenContactDialog, setIsOpenContactDialog] = useState(false);

    function hasOptionInPrice(code){
        return selectedOptions.some(option => option.code === code);
    }

    const reservationClickHandler = () => {
        analytics.sendEvent({
            category: 'Zarezerwuj',
            action: 'Klikniecie',
            label: 'Kliknieto w Zarezerwuj',
            value: 1
        });

        let calculationOffer = {
            vehicle: props.offer,
            calculation: {
                vehicleValueNet: props.offer.minimumPriceNet,
                vehicleValueGross: props.offer.minimumPriceGross,
                buyoutValueNet: 0,
                buyoutValueGross: 0,
                initialFeeValueNet: MathUtils.valueOfPercentage(vehiclePrice, calculationResult.initialFeePercentage),
                initialFeeValueGross: MathUtils.valueOfPercentage(vehiclePrice, calculationResult.initialFeePercentage),
                installmentNet: calculationResult.value,
                installmentGross: calculationResult.value,
                contractLength: calculationParameters.contractLength,
                insuranceLength: hasOptionInPrice("RENTAL_OC_AC") ? calculationParameters.contractLength : 0,
                includeGapInsurance: hasOptionInPrice("GAP"),
                installmentWithoutInsurance: calculationResult.value,
                ocAcInsuranceInstallment: 0,
                gapInstallment: 0,
                vehicleMileageLimit: calculationParameters.vehicleMileage
            }
        };

        props.reservationStrategy(true, calculationOffer);

    }

    const contactClickHandler = () => {
        setIsOpenContactDialog(true);

        analytics.sendEvent({
            category: 'Zapytaj',
            action: 'Klikniecie',
            label: 'Kliknieto w Zapytaj',
            value: 1
        });
    }

    return (
        <Fragment>
            <BaseInstallment
                installment={
                    {
                        net: calculationResult.value,
                        gross: calculationResult.value
                    }
                }
                offer={props.offer}
                label="Rata wynajmu"
            />

            <hr/>

            <form action="">

                 <ButtonChooser
                    items={allowedCalculationParameters.availableContractLengths}
                    defaultValue={calculationParameters.contractLength}
                    onChange={contractLengthChangeHandler}
                    label="Okres wynajmu (ilość miesięcznych rat)"
                />

                <ButtonChooser
                    items={allowedCalculationParameters.availableInitialFees}
                    defaultValue={calculationParameters.initialFeePercentage}
                    onChange={initialFeeChangeHandler}
                    unit="%"
                    label={(<LabelWithInfo infoCode="oplata-wstepna" strongTitle={true}/>)}
                />

                <ButtonChooser
                    items={allowedCalculationParameters.availableVehicleMileages}
                    defaultValue={calculationParameters.vehicleMileage / 1000}
                    onChange={vehicleMileageChangeHandler}
                    label={(<LabelWithInfo infoCode="roczny-limit-km" strongTitle={true}/>)}
                />

                <hr/>
                <RentalCalculationSummary
                    calculation={{...calculationResult, vehiclePrice: vehiclePrice}}
                    calculationParameters={calculationParameters}
                    offerElements={selectedOptions}
                />

                <InstallmentSubmitBox
                    installment={{
                        net: calculationResult.value,
                        gross: calculationResult.value
                    }}
                    onReservationClick={reservationClickHandler}
                    onSendMessageClick={contactClickHandler}
                />
            </form>
            <ContactDialog isOpen={isOpenContactDialog}
                           onClose={() => setIsOpenContactDialog(false)}
                           additionalMessage={"Dotyczy oferty: " + window.location.href}
            />
        </Fragment>
    );
}

export default RentalInstallmentCalculator;