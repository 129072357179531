export const sortTypes = [
    {
        label: "Rata - od najniższej",
        value: "INSTALLMENT_ASC"
    },
    {
        label: "Rata - od najwyższej",
        value: "INSTALLMENT_DESC"
    },
    {
        label: "Rabat - od najwyższego",
        value: "DISCOUNT_DESC"
    },
    {
        label: "Rabat - od najniższego",
        value: "DISCOUNT_ASC"
    },
    {
        label: "Moc silnika - od najwyższej",
        value: "HORSEPOWER_DESC"
    },
    {
        label: "Moc silnika - od najmniejszej",
        value: "HORSEPOWER_ASC"
    },
    {
        label: "Nazwa - od A do Z",
        value: "NAME_ASC"
    },
    {
        label: "Nazwa - od Z do A",
        value: "NAME_DESC"
    },
    {
        label: "Data publikacji - od najnowszej",
        value: "ADDED_DATE_ASC"
    },
    {
        label: "Data publikacji - od najstarszej",
        value: "ADDED_DATE_DESC"
    }

];
