import React, {Fragment, useState} from "react";
import {Checkbox, Collapse} from "@blueprintjs/core";
import InputErrorMessage from "./InputErrorMessage";

function Agreements({onChange, formValidation, checkedAgreements, hiddenAgreements = []}) {

    const agreementsTexts = [
        "Wyrażam zgodę na przetwarzanie moich danych osobowych przekazanych w formularzu i umożliwienia " +
        "funkcjonowania bazy kontaktowej, w której będę pozostawać również w przypadku niezrealizowania leasingu, w " +
        "celu podnoszenia jakości produktów i usług przez Spółkę GO-leasing. ",
        "Upoważniam GO LEASING Sp. z o.o., ul. Towarowa 9F lok.49, 10-416 Olsztyn oraz firmy współpracujące* " +
        "do pozyskania z Biur Informacji Gospodarczej oraz Kredytowej informacji niezbędnych do dokonania oceny mojej " +
        "wiarygodności płatniczej oraz oceny ryzyka kredytowego. Jestem świadomy, że wymienione dane osobowe " +
        "wykorzystane zostaną w celu pozyskania ww. informacji, niezbędnych w ramach realizacji składanego wniosku.",
        "Wyrażam zgodę na otrzymywanie informacji marketingowych za pomocą środków komunikacji elektronicznej.",
        "Wyrażam zgodę na otrzymywanie informacji marketingowych przy użyciu telekomunikacyjnych urządzeń " +
        "końcowych oraz automatycznych systemów wywołujących."];

    const [allChecked, setAllChecked] = useState(false);
    const [agreements, setAgreements] = useState({
        agreement_1: {
            value: agreementsTexts[0],
            checked: checkedAgreements.includes(agreementsTexts[0]),
            visible: !hiddenAgreements.includes(0),
            collapse: false
        },
        agreement_2: {
            value: agreementsTexts[1],
            checked: checkedAgreements.includes(agreementsTexts[1]),
            visible: !hiddenAgreements.includes(1),
            collapse: false
        },
        agreement_3: {
            value: agreementsTexts[2],
            checked: checkedAgreements.includes(agreementsTexts[2]),
            visible: !hiddenAgreements.includes(2),
            collapse: false
        },
        agreement_4: {
            value: agreementsTexts[3],
            checked: checkedAgreements.includes(agreementsTexts[3]),
            visible: !hiddenAgreements.includes(3),
            collapse: false
        }
    });


    const allAgreementChangeHandler = () => {
        let updatedAgreements = {...agreements};

        let checkedAgreements = [];
        for (const name in updatedAgreements) {
            updatedAgreements[name].checked = !allChecked;

            if (updatedAgreements[name].checked === true && updatedAgreements[name].visible) {
                checkedAgreements.push(updatedAgreements[name].value);
            }
        }

        setAgreements(updatedAgreements);
        setAllChecked(prevState => !prevState);

        onChange(checkedAgreements);
    }


    const agreementChangeHandler = (name) => {
        let updatedAgreements = {...agreements};
        let agreement = {...agreements[name]};
        agreement.checked = !agreement.checked;

        updatedAgreements[name] = agreement;
        setAgreements(updatedAgreements);

        let checkedAgreements = [];
        for (const name in updatedAgreements) {
            if (updatedAgreements[name].checked === true) {
                checkedAgreements.push(updatedAgreements[name].value);
            }
        }

        onChange(checkedAgreements);
    }

    const toggleCollapseHandler = (name) => {
        let agreement = {...agreements[name]};
        agreement.collapse = !agreement.collapse;

        setAgreements(prevState => ({
            ...prevState,
            [name]: agreement
        }));
    }

    return (
        <Fragment>
            <Checkbox checked={allChecked}
                      style={{flexBasis: "100%"}}
                      onChange={allAgreementChangeHandler}>
                <strong>Zaznacz wszystkie</strong>
            </Checkbox>
            <InputErrorMessage error={formValidation.errors.agreement_1}/>
            {agreements["agreement_1"].visible &&
            <Checkbox checked={agreements["agreement_1"].checked}
                      name="agreement_1"
                      inputRef={formValidation.register({required: true})}
                      onChange={() => agreementChangeHandler("agreement_1")}>
                <strong>*</strong> {agreements["agreement_1"].value}
                &nbsp;Więcej informacji&nbsp;
                <span className="as-link" onClick={() => toggleCollapseHandler("agreement_1")}>tutaj</span>.

                <Collapse isOpen={agreements["agreement_1"].collapse} keepChildrenMounted={true}>
                    <div className="agreement-info">
                        Zgoda może być wycofana w dowolnym momencie, np. poprzez link znajdujący się na końcu każdej
                        informacji przesłanej elektronicznie w oparciu o udzieloną zgodę lub zwrotny sms - co prowadzi
                        do usunięcia zebranych na podstawie danej zgody danych.<br/>
                        <a href="/rodo" target="_blank">Informacje o Administratorze Twoich danych</a>
                    </div>
                </Collapse>

            </Checkbox>
            }

            <InputErrorMessage error={formValidation.errors.agreement_2}/>
            {agreements["agreement_2"].visible &&
            <Checkbox checked={agreements["agreement_2"].checked}
                      name="agreement_2"
                      inputRef={formValidation.register({required: true})}
                      onChange={() => agreementChangeHandler("agreement_2")}>
                <strong>*</strong> {agreements["agreement_2"].value}
                &nbsp;Więcej informacji&nbsp;
                <span className="as-link" onClick={() => toggleCollapseHandler("agreement_2")}>tutaj</span>.

                <Collapse isOpen={agreements["agreement_2"].collapse} keepChildrenMounted={true}>
                    <div className="agreement-info">
                        Na podstawie art. 24 ust. 1 ustawy z dnia 9 kwietnia 2010 roku o udostępnianiu informacji
                        gospodarczych i wymianie danych gospodarczych (t. j. Dz. U. z 2019 r., poz. 681 ze zm.) oraz na
                        podstawie art. 105 ust. 4a i 4a1 ustawy z dnia 29 sierpnia 1997 r. Prawo bankowe
                        (t. j. Dz. U. z 2019 r. poz. 2357 ze zm.) w związku z art. 13 ustawy o udostępnianiu informacji
                        gospodarczych i wymianie danych gospodarczych upoważniam GO-leasing Sp. z o.o. z siedzibą
                        w Olsztynie przy ulicy Towarowej 9F lok. 49 oraz firmy współpracujące* do:
                        <ul>
                            <li>Pozyskania z Biura Informacji Gospodarczej InfoMonitor S.A. z siedzibą w Warszawie przy
                                ul. Zygmunta Modzelewskiego 77 (BIG InfoMonitor) oraz Krajowego Rejestru Długów Biura
                                Informacji Gospodarczej SA dotyczących mnie informacji gospodarczych,
                            </li>
                            <li>Pozyskania za pośrednictwem BIG InfoMonitor danych gospodarczych z Biura Informacji
                                Kredytowej S.A. i Związku Banków Polskich, w tym między innymi oceny punktowej
                                (scoring), w zakresie niezbędnym do dokonania oceny wiarygodności płatniczej oraz oceny
                                ryzyka kredytowego, a także dotyczących mojego wymagalnego od co najmniej 60 dni
                                zadłużenia wobec banków lub instytucji upoważnionych do udzielania kredytów,
                                przekraczającego 200 zł (dwieście złotych) lub braku danych o takim zadłużeniu,
                            </li>
                            <li>Pozyskania z BIG InfoMonitor informacji dotyczących składanych zapytań na mój temat do
                                Rejestru BIG InfoMonitor w ciągu ostatnich 12 miesięcy.
                            </li>
                        </ul>
                        Oświadczam, iż jestem świadomy, że wymienione dane osobowe wykorzystane zostaną w celu
                        pozyskania ww. informacji.<br/>
                        * Jako Firmy współpracujące rozumie się firmy leasingowe i kontrahenci współpracujący z GO
                        leasing Sp. z o.o.
                        <br/>
                        <a href="/rodo" target="_blank">Informacje o Administratorze Twoich danych</a>
                    </div>
                </Collapse>
            </Checkbox>
            }

            {agreements["agreement_3"].visible &&
            <Checkbox checked={agreements["agreement_3"].checked}
                      onChange={() => agreementChangeHandler("agreement_3")}>
                {agreements["agreement_3"].value}
                &nbsp;Więcej informacji&nbsp;
                <span className="as-link" onClick={() => toggleCollapseHandler("agreement_3")}>tutaj</span>.

                <Collapse isOpen={agreements["agreement_3"].collapse} keepChildrenMounted={true}>
                    <div className="agreement-info">
                        Wyrażona zgoda dotyczy produktów i usług oferowanych przez GO-leasing Sp. z o. o. oraz sieć
                        partnerską (dalej GO-leasing), stosownie do treści przepisu art. 10 ust. 1 i 2 ustawy o
                        świadczeniu usług drogą elektroniczną. Zgoda może być wycofana w dowolnym momencie, np. poprzez
                        link znajdujący się na końcu każdej informacji przesłanej elektronicznie w oparciu o udzieloną
                        zgodę lub zwrotny sms - co prowadzi do usunięcia zebranych na podstawie danej zgody danych.<br/>
                        <a href="/rodo" target="_blank">Informacje o Administratorze Twoich danych</a>
                    </div>
                </Collapse>
            </Checkbox>
            }

            {agreements["agreement_4"].visible &&
            <Checkbox checked={agreements["agreement_4"].checked}
                      onChange={() => agreementChangeHandler("agreement_4")}>
                {agreements["agreement_4"].value}
                &nbsp;Więcej informacji&nbsp;
                <span className="as-link" onClick={() => toggleCollapseHandler("agreement_4")}>tutaj</span>.

                <Collapse isOpen={agreements["agreement_4"].collapse} keepChildrenMounted={true}>
                    <div className="agreement-info">
                        Wyrażona zgoda dotyczy produktów i usług oferowanych przez GO-leasing Sp. z o. o. oraz sieć
                        partnerską (dalej GO-leasing), za pośrednictwem telekomunikacyjnych urządzeń końcowych oraz
                        automatycznych systemów wywołujących w rozumieniu ustawy Prawo telekomunikacyjne. Zgoda może być
                        wycofana w dowolnym momencie.<br/>
                        <a href="/rodo" target="_blank">Informacje o Administratorze Twoich danych</a>
                    </div>
                </Collapse>
            </Checkbox>
            }

            <div className="bp3-text-small">
                <p>* Pola wymagane </p>
                <p>
                    Przesyłane informacje mogą zawierać spersonalizowane komunikaty na temat oferty, promocji,
                    GO-leasing, przygotowane specjalnie dla mnie. Podając swoje dane wyrażam zgodę na utworzenie
                    spersonalizowanego profilu użytkownika, w celu dopasowania do moich potrzeb i zainteresowań
                    reklam GO-leasing w formie elektronicznej oraz drukowanej. Więcej informacji w naszym&nbsp;
                    <a href="/regulamin-serwisu" target="_blank">Regulaminie świadczenia usług</a>,&nbsp;
                    <a href="/rodo" target="_blank">Informacji o Administratorze Twoich danych</a> oraz&nbsp;
                    <a href="/polityka-prywatnosci" target="_blank">Polityce prywatności</a>
                </p>
            </div>
        </Fragment>
    );
}

export default Agreements;