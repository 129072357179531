import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import {useMediaQuery} from 'react-responsive';
import {useHistory} from "react-router";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import mainImage from "../../assets/images/content/glowna_foto.png";
import mainImageMobile from "../../assets/images/content/glowna_foto_mobile.jpg";
import dostawczeImage from "../../assets/images/content/dostawcze_final_v2.jpg";

import BrandAndModelSearch from "../../components/ProductSearchField/BrandAndModelSearch";
import RangeSelect from "../../components/ProductSearchField/RangeSelect";
import MultipleItemsSearchSelect from "../../components/ProductSearchField/MultipleItemsSearchSelect";
import VehicleTypeSwitch from '../../components/ProductSearchField/VehicleTypeSwitch';
import OurStrengths from "./OurStrengths";

import {SearchOptionsContext} from "../../context/search-options-context";
import {CustomerTypeContext} from "../../context/customer-type-context";

import URLSearchParamsConverter from "../../utils/URLSearchParamsConverter";
import analytics from "../../utils/analytics";


function Intro() {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'});
    const backgroundImage = isMobile ? mainImageMobile : mainImage;
    let backgroundStyle = {
        background: `url(${backgroundImage}) no-repeat #fff`,
        backgroundSize: "cover"
    }

    let searchOptions = useContext(SearchOptionsContext);
    let searchOptionsForDeliveryCars = searchOptions.deliveryCars;
    let searchOptionsForPassengerCars = searchOptions.passengerCars;
    const customerType = useContext(CustomerTypeContext)[0];
    const history = useHistory();

    const [searchParameters, setSearchParameters] = useState({
        vehicleTypes: "OSOBOWE",
        brands: [],
        models: [],
        segments: [],
        bodyTypes: [],
        installmentNetFrom: "",
        installmentNetTo: "",
        installmentGrossFrom: "",
        installmentGrossTo: ""
    });

    const changePropertyHandler = (propertyName, value) => {
        setSearchParameters(prevSearchParameters => {
                let searchParams = prevSearchParameters;
                searchParams[propertyName] = value;

                return searchParams;
            }
        );
    };

    const changeBudgetHandler = (budget) => {
        if (customerType === searchOptions.customerTypes.company) {
            setSearchParameters(prevSearchParameters =>
                ({
                    ...prevSearchParameters,
                    installmentNetFrom: budget.from,
                    installmentNetTo: budget.to,
                    installmentGrossFrom: null,
                    installmentGrossTo: null
                }));
        } else {
            setSearchParameters(prevSearchParameters =>
                ({
                    ...prevSearchParameters,
                    installmentGrossFrom: budget.from,
                    installmentGrossTo: budget.to,
                    installmentNetFrom: null,
                    installmentNetTo: null,
                }));
        }
    };

    const searchButtonClicked = (openFIlter) => {

        analytics.sendEvent({
            category: 'BOX - Szukaj',
            action: 'Klikniecie',
            label: 'Kliknieto w Szukaj',
            value: 1
        });

        history.push({
            pathname: "/lista-ofert",
            search: URLSearchParamsConverter.createURLSearchString(searchParameters) + (openFIlter ? "&filterClicked=true" : "")
        });
    }

    const vehicleTypeHandler = (type) => {
        setSearchParameters(prevSearchParameters =>
            ({
                ...prevSearchParameters,
                vehicleTypes: type,
            }));
    }

    const vehicleTypesSegmentsSwitch = () => {
        if (searchParameters.vehicleTypes === "OSOBOWE") {
            return (
                <MultipleItemsSearchSelect
                    items={searchOptions.segments}
                    onChange={(segments) => changePropertyHandler("segments", segments)}
                    placeholder="Wybierz segment"/>
            );
        } else {
            return (
                <MultipleItemsSearchSelect
                    items={searchOptions.bodyTypes}
                    onChange={(bodyTypes) => changePropertyHandler("bodyTypes", bodyTypes)}
                    placeholder="Wybierz nadwozie"
                />);
        }
    }

    let installmentRange = {from: searchParameters.installmentNetFrom, to: searchParameters.installmentNetTo};
    if (customerType !== searchOptions.customerTypes.company) {
        installmentRange = {from: searchParameters.installmentGrossFrom, to: searchParameters.installmentGrossTo};
    }

    if (searchParameters.vehicleTypes === "DOSTAWCZE") {
        backgroundStyle = {
            background: `url(${dostawczeImage}) no-repeat #fff`,
        }
        searchOptions = searchOptionsForDeliveryCars;
    }

    if (searchParameters.vehicleTypes === "OSOBOWE") {
        searchOptions = searchOptionsForPassengerCars;
    }

    return (
        <div id="home-intro" style={backgroundStyle}>
            <div className="container flex flex-wrap mobile-reverse">
                <OurStrengths vehicleType={searchParameters.vehicleTypes}/>
                <div id="search-form" className="container">
                    <form action="">
                        <h3 className="center">Szukaj auta</h3>

                        <VehicleTypeSwitch
                            onChange={vehicleTypeHandler}
                        />

                        <BrandAndModelSearch
                            brandsAndModels={searchOptions.brandsAndModels}
                            onBrandChange={(brands) => changePropertyHandler("brands", brands)}
                            onModelChange={(models) => changePropertyHandler("models", models)}
                        />

                        {vehicleTypesSegmentsSwitch()}

                        <RangeSelect
                            onChange={changeBudgetHandler}
                            items={searchOptions.installments}
                            range={installmentRange}
                            unit="zł"
                            placeholder={["Rata od", "Rata do"]}
                        />
                        <button type="button" onClick={() => searchButtonClicked(false)}>Szukaj</button>
                            <div className="advancedSearch" onClick={() => searchButtonClicked(true)}>Wyszukiwanie zaawansowane</div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Intro;