import React, {useContext, useEffect, useState} from "react";
import FilterSortButtonsBar from "./FilterSortButtonsBar";
import FiltersDrawer from "./FiltersDrawer";
import PageTitle from "../../components/PageTitle/PageTitle";
import backgroundImage from "../../assets/images/content/offer_page_bg.png";
import SortDrawer from "./SortDrawer";
import OfferList from "./OfferList";
import MetaTagsManipulator from "../../utils/MetaTagsManipulator";
import UrlDecorator from "../../utils/UrlDecorator";
import URLSearchParamsConverter from "../../utils/URLSearchParamsConverter";
import {useLocation, withRouter} from "react-router";
import {goVehisService} from "../../utils/axios";
import {CustomerTypeContext} from "../../context/customer-type-context";

function OfferListPage(props) {

    MetaTagsManipulator.setTitle("Lista ofert");
    MetaTagsManipulator.setDescription("Lista ofert");

    const content = "<div>\n" +
        "                <p>Wybierz spośród 5000 aut dostępnych od ręki.</p>\n" +
        "                <p>Policz ratę, zarezerwuj i złóż wniosek online.</p>\n" +
        "                <p>Odbiór bezpośrednio u dilera lub dostawa pod Twój dom</p>\n" +
        "                <p>Szybko, Prosto, Wygodnie, Bezpiecznie.</p>\n" +
        "            </div>";

    UrlDecorator.addSellerCode();

    const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false);
    const [isSortDrawerOpen, setIsSortDrawerOpen] = useState(false);
    const [offerViewType, setOfferViewType] = useState("grid-view");
    const [offersNumber, setOffersNumber] = useState("Ładowanie...");

    const setCompanyOnly = useContext(CustomerTypeContext)[4];
    const customerContextType = useContext(CustomerTypeContext)[0];

    const filterButtonClickHandler = () => {
        setIsFiltersDrawerOpen(true);
    }
    const filtersDrawerCloseHandler = () => setIsFiltersDrawerOpen(false);

    const sortButtonClickHandler = () => setIsSortDrawerOpen(true);
    const sortDrawerCloseHandler = () => setIsSortDrawerOpen(false);
    const viewTypeChangeHandler = viewType => setOfferViewType(viewType);

    const initialState = {
        brands: [],
        models: [],
        bodyTypes: [],
        fuelTypes: [],
        segments: [],
        installmentNetFrom: "",
        installmentNetTo: "",
        installmentGrossFrom: "",
        installmentGrossTo: "",
        minimumSellPriceNetFrom: "",
        minimumSellPriceNetTo: "",
        minimumSellPriceGrossFrom: "",
        minimumSellPriceGrossTo: "",
        mileageFrom: "",
        mileageTo: "",
        manufactureYearFrom: "",
        manufactureYearTo: "",
        enginePowerHpFrom: "",
        enginePowerHpTo: "",
        gearboxTypes: [],
        driveTypes: [],
        colors: [],
        numberOfDoors: [],
        numberOfSeats:[],
        equipment:[],
        availability: false,
        specialOffer: false,
        isPremium: false,
        vehicleTypes: "OSOBOWE",
        newUsed: "all",
        calculationTypes: [],
        nip: "",
        createdFrom: "",
        createdTo: "",
        sources: []
    };

    const [searchParameters, setSearchParameters] = useState(initialState);

    let location = useLocation();

    useEffect(() => {
        setIsFiltersDrawerOpen(URLSearchParamsConverter.getSearchParamsFromURLSearchStringForAdvancedSearch(location.search));
        setCompanyOnly(false);
    }, []);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(props.location.search);
        const offersLimit = "1000000";
        urlSearchParams.set("limit", offersLimit);
        urlSearchParams.set("customerType", customerContextType)

        goVehisService.get("/vehicles/count?" + urlSearchParams).then(response => {
            setOffersNumber(response.data);
        });
    },[props.location.search, customerContextType]);

    return (
        <div className="lista-ofert">

            <FiltersDrawer isOpen={isFiltersDrawerOpen}
                           title="Zobacz samochody"
                           onClose={filtersDrawerCloseHandler}
                           setSearchParameters={(params) => setSearchParameters(params)}
                           searchParameters={searchParameters}
                           initialState={initialState}/>

            <SortDrawer isOpen={isSortDrawerOpen} title="Wybierz sortowanie" onClose={sortDrawerCloseHandler}/>

            <PageTitle title={"Lista ofert (" + offersNumber + ")"} backgroundImage={backgroundImage} content={content}/>

            <FilterSortButtonsBar
                viewType={offerViewType}
                filterClicked={filterButtonClickHandler}
                sortClicked={sortButtonClickHandler}
                onViewTypeChange={viewTypeChangeHandler}
                showFilterButton={true}
            />

            <OfferList viewType={offerViewType}
                       setSearchParameters={(params) => setSearchParameters(params)}
                       searchParameters={searchParameters}/>

        </div>
    );
}

export default withRouter(OfferListPage);