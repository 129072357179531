import React, {Fragment, useEffect, useState} from "react";
import SimpleSuggest from "../SimpleSuggest/SimpleSuggest";
import Validator from "../../utils/Validator";

function RangeSelect(props) {

    const [rangeFromList, setRangeFromList] = useState([]);

    const [rangeToList, setRangeToList] = useState([]);
    const [rangeFromQuery, setRangeFromQuery] = useState("");
    const [rangeToQuery, setRangeToQuery] = useState("");
    const [range, setRange] = useState({
        from: "",
        to: ""
    })
    const unitsToCutRangeTo = ["km"]

    useEffect(() => {
        if(props.range) {
            setRange(props.range);
        }
    }, []);

    useEffect(() => {
        const listValues = props.reverseValuesList ? props.items.reverse() : props.items;

        if(unitsToCutRangeTo.includes(props.unit)){
            setRangeFromList(listValues.slice(0, -1));
        }else {
            setRangeFromList(listValues);
        }

        setRangeToList(listValues);
    }, [props.items]);

    useEffect(() => {

        if(Validator.nonNumbersExistsInString(rangeFromQuery)){
            setRangeFromQuery(rangeFromQuery.slice(0, -1))
        }

        if(Validator.nonNumbersExistsInString(rangeToQuery)){
            setRangeToQuery(rangeToQuery.slice(0, -1))
        }

    }, [rangeFromQuery, rangeToQuery])

    const setRangeFrom = (selectedItem) => {
        setRangeFromQuery(selectedItem);
        setRange({...range, from: selectedItem});
        props.onChange(range);
    };

    const setRangeTo = (selectedItem) => {
        setRangeToQuery(selectedItem);
        setRange({...range, to: selectedItem});
        props.onChange(range);
    };

    return (
        <Fragment>
            <div className="half-select">

                <SimpleSuggest
                    initValue={range.from}
                    unit={props.unit}
                    rangeQuery={rangeFromQuery}
                    updateRangeFromInput={setRangeFrom}
                    rangeList={rangeFromList}
                    placeholder={props.placeholder[0]}/>
                <SimpleSuggest
                    initValue={range.to}
                    unit={props.unit}
                    rangeQuery={rangeToQuery}
                    updateRangeFromInput={setRangeTo}
                    rangeList={rangeToList}
                    placeholder={props.placeholder[1]}/>

            </div>
        </Fragment>
    );
}

export default RangeSelect;